import AppLoadingSpinner from '@components/LoadingSpinner/AppLoadingSpinner';
import * as ROUTES from '@config/routes';
import theme from '@config/theme';
import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper';
const adminOrElse = (Component, FailureComponent) =>
    connectedAuthWrapper({
        authenticatedSelector: ({ firebase: { profile } }) =>
            profile.role === 'super' && profile.platformName === theme.platformInfo.name,
        wrapperDisplayName: 'AdminOrElse',
        FailureComponent,
    })(Component);

const Root = lazy(() => import('@containers/Root'));
const Alerts = lazy(() => import('@containers/Alerts'));
const Login = lazy(() => import('@containers/Auth/Login'));
const Forgot = lazy(() => import('@containers/Auth/Forgot'));
const Register = lazy(() => import('@containers/Auth/Register'));
const RegisterAgent = lazy(() => import('@containers/Auth/RegisterAgent'));
const Dashboard = lazy(() => import('@containers/Dashboard'));
const DealDetails = lazy(() => import('@containers/DealDetails'));
const EmailVerification = lazy(() => import('@containers/EmailVerification'));
const FailedReservation = lazy(() => import('@containers/FailedReservation'));
const About = lazy(() => import('@containers/Marketing/About'));
const Contact = lazy(() => import('@containers/Marketing/Contact'));
const SourcingAgents = lazy(() => import('@containers/Marketing/SourcingAgents'));
const Investors = lazy(() => import('@containers/Marketing/Investors'));
const MyDeals = lazy(() => import('@containers/MyDeals'));
const MyDocuments = lazy(() => import('@containers/MyDocuments/MyDocuments'));
const AgentDocuments = lazy(() => import('@containers/AgentDocuments/AgentDocuments'));
const NotFound = lazy(() => import('@containers/NotFound'));
const Terms = lazy(() => import('@containers/Terms'));
const Pricing = lazy(() => import('@containers/Pricing/PricingPlans'));
const ProfileDetails = lazy(() => import('@containers/ProfileDetails/ProfileDetails'));
const SuccessfulReservation = lazy(() => import('@containers/SuccessfulReservation'));
const SuccessfulSubscription = lazy(() => import('@containers/SuccessfulSubscription'));
const Watchlist = lazy(() => import('@containers/Watchlist'));
const ListingEditor = lazy(() =>
    import('@containers/SourcingAgentPortal/AddListing/ListingEditor'),
);
const SourcingAgentsDashboard = lazy(() =>
    import('@containers/SourcingAgentPortal/MyListings/index'),
);
const AdminPortal = lazy(() => import('@containers/AdminPortal/index'));
const AdminPortalInvestors = lazy(() => import('@containers/AdminPortal/Investors/index'));
const AdminPortalAgents = lazy(() => import('@containers/AdminPortal/Agents/index'));
const AdminPortalListings = lazy(() => import('@containers/AdminPortal/Listings/index'));
export default function Routes() {
    return (
        <Router>
            <Suspense fallback={<AppLoadingSpinner />}>
                <Switch>
                    <Route component={Root} exact path={ROUTES.ROOT}>
                        <Redirect to={ROUTES.LOGIN} />
                    </Route>
                    <Route component={Investors} exact path={ROUTES.INVESTORS} />
                    <Route component={SourcingAgents} exact path={ROUTES.SOURCING_AGENTS} />
                    <Route
                        component={SourcingAgentsDashboard}
                        exact
                        path={ROUTES.SOURCING_AGENTS_DASHBOARD}
                    />
                    <Route component={About} exact path={ROUTES.ABOUT} />
                    <Route component={ListingEditor} exact path={ROUTES.ADD_LISTING} />
                    <Route component={ListingEditor} exact path={ROUTES.EDIT_LISTING} />
                    <Route component={Contact} exact path={ROUTES.CONTACT} />
                    <Route component={Login} exact path={ROUTES.LOGIN} />
                    <Route component={Forgot} exact path={ROUTES.FORGOT} />
                    <Route component={RegisterAgent} exact path={ROUTES.REGISTER_AGENT} />
                    <Route component={Register} exact path={ROUTES.REGISTER_INVESTOR} />
                    <Route component={Register} exact path={ROUTES.REGISTER_MOBILE} />
                    {/* <Route component={Dashboard} exact path={ROUTES.DASHBOARD} /> */}
                    <Route
                        component={adminOrElse(AdminPortal, Dashboard)}
                        exact
                        path={ROUTES.DASHBOARD}
                    />
                    <Route
                        component={adminOrElse(AdminPortalInvestors, Dashboard)}
                        exact
                        path={ROUTES.ADMIN_INVESTORS}
                    />
                    <Route
                        component={adminOrElse(AdminPortalListings, Dashboard)}
                        exact
                        path={ROUTES.ADMIN_LISTINGS}
                    />
                    <Route
                        component={adminOrElse(AdminPortalAgents, Dashboard)}
                        exact
                        path={ROUTES.ADMIN_AGENTS}
                    />
                    <Route component={MyDeals} exact path={ROUTES.MYDEALS} />
                    <Route component={Alerts} exact path={ROUTES.ALERTS} />
                    <Route component={Watchlist} exact path={ROUTES.WATCHLIST} />
                    <Route
                        component={SuccessfulReservation}
                        exact
                        path={ROUTES.SUCCESSFULRESERVATION}
                    />
                    <Route
                        component={SuccessfulSubscription}
                        exact
                        path={ROUTES.SUCCESSFULSUBSCRIPTION}
                    />
                    <Route component={FailedReservation} exact path={ROUTES.FAILEDRESERVATION} />
                    <Route component={DealDetails} exact path={ROUTES.DEAL_DETAILS} />
                    <Route component={Pricing} exact path={ROUTES.PRICING} />
                    <Route component={EmailVerification} exact path={ROUTES.VERIFY_EMAIL} />
                    <Route component={ProfileDetails} exact path={ROUTES.PROFILE} />
                    <Route component={MyDocuments} exact path={ROUTES.MY_DOCUMENTS} />
                    <Route component={AgentDocuments} exact path={ROUTES.AGENT_DOCUMENTS} />
                    <Route component={Terms} exact path={ROUTES.TERMS} />
                    <Route component={NotFound} />
                </Switch>
            </Suspense>
        </Router>
    );
}

Routes.propTypes = {};
