export var config = {
    apiKey: 'AIzaSyDfYHn5xmmjuujBOtleN09xzOYsICVns3g',
    authDomain: 'dealprop-db86f.firebaseapp.com',
    databaseURL: 'https://dealprop-db86f.firebaseio.com',
    projectId: 'dealprop-db86f',
    storageBucket: 'dealprop-db86f.appspot.com',
    messagingSenderId: '53025145571',
    appId: '1:53025145571:web:5fcdd9af6fbe8bb8d03bd6',
    measurementId: 'G-0WX4TSWDVG',
};
